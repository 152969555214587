//

const getBrowserLocale = () => {
  const lng = window.navigator.language;
  return !lng || lng === "en-US" ? "en-AU" : lng;
};

const locale = getBrowserLocale();
console.log("browser locale: ", locale);

const mediumDateFormat = d => {
  return new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    year: undefined,
  }).format(new Date(d));
};

const longDateFormat = d => {
  return new Intl.DateTimeFormat(locale, { dateStyle: "medium" }).format(new Date(d));
};

const timeFormat = d => {
  return new Intl.DateTimeFormat(locale, { timeStyle: "short", hour12: true })
    .format(new Date(d))
    .replace(/ /g, "");
};

export { mediumDateFormat, timeFormat, longDateFormat };
