/* routes/Comp.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	handle_promise,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_await_block_branch
} from "svelte/internal";

import { get } from "../utils/api";
import TeamSlot from "../components/TeamSlot.svelte";
import Tabs from "../components/Tabs.svelte";
import Loading from "../components/Loading.svelte";
import PageTitle from "../components/PageTitle.svelte";
import Error from "../components/Error.svelte";
import Info from "../components/Info.svelte";
import Ladder from "../components/Ladder.svelte";
import Icon from "fa-svelte";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import EditTeams from "../components/EditTeams.svelte";
import StartDutyButton from "../components/StartDutyButton.svelte";
import Admin from "../components/Admin.svelte";

import {
	mapStartToDate,
	sortGames,
	getFields,
	getStartedPools,
	ladderForPool,
	calcGoalsForTeams,
	getGoogleUrl,
	getDays
} from "../utils/compUtils";

import PlayerLadder from "../components/PlayerLadder.svelte";
import { timeFormat } from "../utils/dateUtils";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	child_ctx[26] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	return child_ctx;
}

// (265:0) {:catch error}
function create_catch_block(ctx) {
	let error;
	let current;

	error = new Error({
			props: {
				message: "An error occurred loading the comp..."
			}
		});

	return {
		c() {
			create_component(error.$$.fragment);
		},
		m(target, anchor) {
			mount_component(error, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(error.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(error, detaching);
		}
	};
}

// (126:0) {:then c}
function create_then_block(ctx) {
	let div;
	let pagetitle;
	let t;
	let tabs;
	let current;
	let mounted;
	let dispose;

	pagetitle = new PageTitle({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	tabs = new Tabs({
			props: {
				tabs: ["Draw", "Ladder", "Player Ladder", "Teams", "Admin"],
				$$slots: {
					tab5: [create_tab5_slot],
					tab4: [create_tab4_slot],
					tab3: [create_tab3_slot],
					tab2: [create_tab2_slot],
					tab1: [create_tab1_slot]
				},
				$$scope: { ctx }
			}
		});

	tabs.$on("tabChange", function () {
		if (is_function(/*updatePools*/ ctx[10])) /*updatePools*/ ctx[10].apply(this, arguments);
	});

	return {
		c() {
			div = element("div");
			create_component(pagetitle.$$.fragment);
			t = space();
			create_component(tabs.$$.fragment);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(pagetitle, div, null);
			append(div, t);
			mount_component(tabs, div, null);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*onTableClick*/ ctx[16]);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const pagetitle_changes = {};

			if (dirty[0] & /*comp*/ 8 | dirty[1] & /*$$scope*/ 8) {
				pagetitle_changes.$$scope = { dirty, ctx };
			}

			pagetitle.$set(pagetitle_changes);
			const tabs_changes = {};

			if (dirty[0] & /*comp, compId, pools, ladder, poolNames, updateLadder, tableBody, filteredGames, selectedTeam, teams, fields, fieldTab*/ 7167 | dirty[1] & /*$$scope*/ 8) {
				tabs_changes.$$scope = { dirty, ctx };
			}

			tabs.$set(tabs_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pagetitle.$$.fragment, local);
			transition_in(tabs.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pagetitle.$$.fragment, local);
			transition_out(tabs.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(pagetitle);
			destroy_component(tabs);
			mounted = false;
			dispose();
		}
	};
}

// (131:8) {#if comp.address}
function create_if_block_4(ctx) {
	let a;
	let icon;
	let t0;
	let t1_value = /*comp*/ ctx[3].address + "";
	let t1;
	let a_href_value;
	let current;
	let mounted;
	let dispose;

	icon = new Icon({
			props: { class: "", icon: faMapMarkerAlt }
		});

	return {
		c() {
			a = element("a");
			create_component(icon.$$.fragment);
			t0 = space();
			t1 = text(t1_value);
			attr(a, "class", "ml-auto");
			attr(a, "href", a_href_value = getGoogleUrl(/*comp*/ ctx[3].address));
		},
		m(target, anchor) {
			insert(target, a, anchor);
			mount_component(icon, a, null);
			append(a, t0);
			append(a, t1);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", click_handler);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*comp*/ 8) && t1_value !== (t1_value = /*comp*/ ctx[3].address + "")) set_data(t1, t1_value);

			if (!current || dirty[0] & /*comp*/ 8 && a_href_value !== (a_href_value = getGoogleUrl(/*comp*/ ctx[3].address))) {
				attr(a, "href", a_href_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			destroy_component(icon);
			mounted = false;
			dispose();
		}
	};
}

// (128:4) <PageTitle>
function create_default_slot(ctx) {
	let div1;
	let div0;
	let t0_value = /*comp*/ ctx[3].name + "";
	let t0;
	let t1;
	let current;
	let if_block = /*comp*/ ctx[3].address && create_if_block_4(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			attr(div0, "class", "mb-0");
			attr(div1, "class", "is-flex mb-1");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, t0);
			append(div1, t1);
			if (if_block) if_block.m(div1, null);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*comp*/ 8) && t0_value !== (t0_value = /*comp*/ ctx[3].name + "")) set_data(t0, t0_value);

			if (/*comp*/ ctx[3].address) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*comp*/ 8) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div1, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

// (142:8) {#if hasMultipleFields()}
function create_if_block_3(ctx) {
	let tabs;
	let current;

	tabs = new Tabs({
			props: {
				tabs: ["All Fields", .../*fields*/ ctx[5].map(func)]
			}
		});

	tabs.$on("tabChange", /*tabChange_handler_1*/ ctx[19]);

	return {
		c() {
			create_component(tabs.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tabs, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tabs_changes = {};
			if (dirty[0] & /*fields*/ 32) tabs_changes.tabs = ["All Fields", .../*fields*/ ctx[5].map(func)];
			tabs.$set(tabs_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tabs.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tabs.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tabs, detaching);
		}
	};
}

// (175:20) {#if dayIndex === 0}
function create_if_block_2(ctx) {
	let div;
	let select;
	let option;
	let mounted;
	let dispose;
	let each_value_2 = /*teams*/ ctx[8];
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	return {
		c() {
			div = element("div");
			select = element("select");
			option = element("option");
			option.textContent = "All Teams";

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			option.__value = "";
			option.value = option.__value;
			attr(div, "class", "select");
			set_style(div, "margin-left", "auto");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, select);
			append(select, option);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			if (!mounted) {
				dispose = [
					listen(select, "change", /*updateTeamFilter*/ ctx[14]),
					listen(div, "click", click_handler_1)
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*teams*/ 256) {
				each_value_2 = /*teams*/ ctx[8];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (183:26) {#each teams as team}
function create_each_block_2(ctx) {
	let option;
	let t_value = /*team*/ ctx[30].name + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*team*/ ctx[30].uuid;
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*teams*/ 256 && t_value !== (t_value = /*team*/ ctx[30].name + "")) set_data(t, t_value);

			if (dirty[0] & /*teams*/ 256 && option_value_value !== (option_value_value = /*team*/ ctx[30].uuid)) {
				option.__value = option_value_value;
				option.value = option.__value;
			}
		},
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

// (209:20) {#if game.gameState === "finished"}
function create_if_block_1(ctx) {
	let span;
	let t_value = `${/*game*/ ctx[27].t1Goals} vs ${/*game*/ ctx[27].t2Goals}` + "";
	let t;

	return {
		c() {
			span = element("span");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*filteredGames, comp*/ 4104 && t_value !== (t_value = `${/*game*/ ctx[27].t1Goals} vs ${/*game*/ ctx[27].t2Goals}` + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (192:14) {#each filteredGames(day) as game}
function create_each_block_1(ctx) {
	let tr;
	let td0;
	let div0;
	let t0;
	let t1_value = /*game*/ ctx[27].field + "";
	let t1;
	let t2;
	let t3_value = timeFormat(/*game*/ ctx[27].start) + "";
	let t3;
	let t4;
	let div1;
	let startdutybutton0;
	let t5;
	let td1;
	let t6_value = /*comp*/ ctx[3].pools[/*game*/ ctx[27].pool].name + "";
	let t6;
	let t7;
	let td2;
	let teamslot0;
	let t8;
	let td3;
	let t9;
	let td4;
	let teamslot1;
	let t10;
	let td5;
	let teamslot2;
	let t11;
	let teamslot3;
	let t12;
	let td6;
	let teamslot4;
	let t13;
	let td7;
	let teamslot5;
	let t14;
	let td8;
	let teamslot6;
	let t15;
	let teamslot7;
	let t16;
	let td9;
	let teamslot8;
	let t17;
	let td10;
	let div2;
	let startdutybutton1;
	let t18;
	let tr_data_name_value;
	let current;

	startdutybutton0 = new StartDutyButton({
			props: {
				game: /*game*/ ctx[27],
				compId: /*compId*/ ctx[0]
			}
		});

	teamslot0 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "t1"
			}
		});

	let if_block = /*game*/ ctx[27].gameState === "finished" && create_if_block_1(ctx);

	teamslot1 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "t2"
			}
		});

	teamslot2 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "t1"
			}
		});

	teamslot3 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "t2"
			}
		});

	teamslot4 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "r1"
			}
		});

	teamslot5 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "r2"
			}
		});

	teamslot6 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "r1"
			}
		});

	teamslot7 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "r2"
			}
		});

	teamslot8 = new TeamSlot({
			props: {
				selectedTeam: /*selectedTeam*/ ctx[7],
				game: /*game*/ ctx[27],
				teams: /*comp*/ ctx[3].teams,
				gameSlot: "duty"
			}
		});

	startdutybutton1 = new StartDutyButton({
			props: {
				game: /*game*/ ctx[27],
				compId: /*compId*/ ctx[0]
			}
		});

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			div0 = element("div");
			t0 = text("Field");
			t1 = text(t1_value);
			t2 = text(": ");
			t3 = text(t3_value);
			t4 = space();
			div1 = element("div");
			create_component(startdutybutton0.$$.fragment);
			t5 = space();
			td1 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td2 = element("td");
			create_component(teamslot0.$$.fragment);
			t8 = space();
			td3 = element("td");
			if (if_block) if_block.c();
			t9 = space();
			td4 = element("td");
			create_component(teamslot1.$$.fragment);
			t10 = space();
			td5 = element("td");
			create_component(teamslot2.$$.fragment);
			t11 = text("\n                    vs\n                    ");
			create_component(teamslot3.$$.fragment);
			t12 = space();
			td6 = element("td");
			create_component(teamslot4.$$.fragment);
			t13 = space();
			td7 = element("td");
			create_component(teamslot5.$$.fragment);
			t14 = space();
			td8 = element("td");
			create_component(teamslot6.$$.fragment);
			t15 = text("\n                    &\n                    ");
			create_component(teamslot7.$$.fragment);
			t16 = space();
			td9 = element("td");
			create_component(teamslot8.$$.fragment);
			t17 = space();
			td10 = element("td");
			div2 = element("div");
			create_component(startdutybutton1.$$.fragment);
			t18 = space();
			attr(div1, "class", "is-hidden-desktop is-hidden-print");
			set_style(td0, "white-space", "nowrap");
			attr(td0, "class", "tableCell svelte-10qe530");
			attr(td1, "class", "svelte-10qe530");
			attr(td2, "class", "is-hidden-touch svelte-10qe530");
			attr(td3, "class", "is-print-only has-text-weight-semibold svelte-10qe530");
			set_style(td3, "white-space", "nowrap");
			attr(td4, "class", "is-hidden-touch svelte-10qe530");
			attr(td5, "class", "is-hidden-desktop svelte-10qe530");
			attr(td6, "class", "is-hidden-touch svelte-10qe530");
			attr(td7, "class", "is-hidden-touch svelte-10qe530");
			attr(td8, "class", "is-hidden-desktop svelte-10qe530");
			attr(td9, "class", "svelte-10qe530");
			attr(div2, "class", "is-hidden-print");
			attr(td10, "class", "is-hidden-touch svelte-10qe530");
			attr(tr, "class", "is-vcentered");
			attr(tr, "data-name", tr_data_name_value = /*game*/ ctx[27].id);
			toggle_class(tr, "has-text-grey", /*game*/ ctx[27].gameState === "finished");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, div0);
			append(div0, t0);
			append(div0, t1);
			append(div0, t2);
			append(div0, t3);
			append(td0, t4);
			append(td0, div1);
			mount_component(startdutybutton0, div1, null);
			append(tr, t5);
			append(tr, td1);
			append(td1, t6);
			append(tr, t7);
			append(tr, td2);
			mount_component(teamslot0, td2, null);
			append(tr, t8);
			append(tr, td3);
			if (if_block) if_block.m(td3, null);
			append(tr, t9);
			append(tr, td4);
			mount_component(teamslot1, td4, null);
			append(tr, t10);
			append(tr, td5);
			mount_component(teamslot2, td5, null);
			append(td5, t11);
			mount_component(teamslot3, td5, null);
			append(tr, t12);
			append(tr, td6);
			mount_component(teamslot4, td6, null);
			append(tr, t13);
			append(tr, td7);
			mount_component(teamslot5, td7, null);
			append(tr, t14);
			append(tr, td8);
			mount_component(teamslot6, td8, null);
			append(td8, t15);
			mount_component(teamslot7, td8, null);
			append(tr, t16);
			append(tr, td9);
			mount_component(teamslot8, td9, null);
			append(tr, t17);
			append(tr, td10);
			append(td10, div2);
			mount_component(startdutybutton1, div2, null);
			append(tr, t18);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*filteredGames, comp*/ 4104) && t1_value !== (t1_value = /*game*/ ctx[27].field + "")) set_data(t1, t1_value);
			if ((!current || dirty[0] & /*filteredGames, comp*/ 4104) && t3_value !== (t3_value = timeFormat(/*game*/ ctx[27].start) + "")) set_data(t3, t3_value);
			const startdutybutton0_changes = {};
			if (dirty[0] & /*filteredGames, comp*/ 4104) startdutybutton0_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*compId*/ 1) startdutybutton0_changes.compId = /*compId*/ ctx[0];
			startdutybutton0.$set(startdutybutton0_changes);
			if ((!current || dirty[0] & /*comp, filteredGames*/ 4104) && t6_value !== (t6_value = /*comp*/ ctx[3].pools[/*game*/ ctx[27].pool].name + "")) set_data(t6, t6_value);
			const teamslot0_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot0_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot0_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot0_changes.teams = /*comp*/ ctx[3].teams;
			teamslot0.$set(teamslot0_changes);

			if (/*game*/ ctx[27].gameState === "finished") {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(td3, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const teamslot1_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot1_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot1_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot1_changes.teams = /*comp*/ ctx[3].teams;
			teamslot1.$set(teamslot1_changes);
			const teamslot2_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot2_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot2_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot2_changes.teams = /*comp*/ ctx[3].teams;
			teamslot2.$set(teamslot2_changes);
			const teamslot3_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot3_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot3_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot3_changes.teams = /*comp*/ ctx[3].teams;
			teamslot3.$set(teamslot3_changes);
			const teamslot4_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot4_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot4_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot4_changes.teams = /*comp*/ ctx[3].teams;
			teamslot4.$set(teamslot4_changes);
			const teamslot5_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot5_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot5_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot5_changes.teams = /*comp*/ ctx[3].teams;
			teamslot5.$set(teamslot5_changes);
			const teamslot6_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot6_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot6_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot6_changes.teams = /*comp*/ ctx[3].teams;
			teamslot6.$set(teamslot6_changes);
			const teamslot7_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot7_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot7_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot7_changes.teams = /*comp*/ ctx[3].teams;
			teamslot7.$set(teamslot7_changes);
			const teamslot8_changes = {};
			if (dirty[0] & /*selectedTeam*/ 128) teamslot8_changes.selectedTeam = /*selectedTeam*/ ctx[7];
			if (dirty[0] & /*filteredGames, comp*/ 4104) teamslot8_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*comp*/ 8) teamslot8_changes.teams = /*comp*/ ctx[3].teams;
			teamslot8.$set(teamslot8_changes);
			const startdutybutton1_changes = {};
			if (dirty[0] & /*filteredGames, comp*/ 4104) startdutybutton1_changes.game = /*game*/ ctx[27];
			if (dirty[0] & /*compId*/ 1) startdutybutton1_changes.compId = /*compId*/ ctx[0];
			startdutybutton1.$set(startdutybutton1_changes);

			if (!current || dirty[0] & /*filteredGames, comp*/ 4104 && tr_data_name_value !== (tr_data_name_value = /*game*/ ctx[27].id)) {
				attr(tr, "data-name", tr_data_name_value);
			}

			if (!current || dirty[0] & /*filteredGames, comp*/ 4104) {
				toggle_class(tr, "has-text-grey", /*game*/ ctx[27].gameState === "finished");
			}
		},
		i(local) {
			if (current) return;
			transition_in(startdutybutton0.$$.fragment, local);
			transition_in(teamslot0.$$.fragment, local);
			transition_in(teamslot1.$$.fragment, local);
			transition_in(teamslot2.$$.fragment, local);
			transition_in(teamslot3.$$.fragment, local);
			transition_in(teamslot4.$$.fragment, local);
			transition_in(teamslot5.$$.fragment, local);
			transition_in(teamslot6.$$.fragment, local);
			transition_in(teamslot7.$$.fragment, local);
			transition_in(teamslot8.$$.fragment, local);
			transition_in(startdutybutton1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(startdutybutton0.$$.fragment, local);
			transition_out(teamslot0.$$.fragment, local);
			transition_out(teamslot1.$$.fragment, local);
			transition_out(teamslot2.$$.fragment, local);
			transition_out(teamslot3.$$.fragment, local);
			transition_out(teamslot4.$$.fragment, local);
			transition_out(teamslot5.$$.fragment, local);
			transition_out(teamslot6.$$.fragment, local);
			transition_out(teamslot7.$$.fragment, local);
			transition_out(teamslot8.$$.fragment, local);
			transition_out(startdutybutton1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_component(startdutybutton0);
			destroy_component(teamslot0);
			if (if_block) if_block.d();
			destroy_component(teamslot1);
			destroy_component(teamslot2);
			destroy_component(teamslot3);
			destroy_component(teamslot4);
			destroy_component(teamslot5);
			destroy_component(teamslot6);
			destroy_component(teamslot7);
			destroy_component(teamslot8);
			destroy_component(startdutybutton1);
		}
	};
}

// (168:12) {#each comp.days as day, dayIndex}
function create_each_block(ctx) {
	let tr;
	let td;
	let div;
	let h5;
	let t0_value = /*day*/ ctx[24] + "";
	let t0;
	let t1;
	let t2;
	let each_1_anchor;
	let current;
	let if_block = /*dayIndex*/ ctx[26] === 0 && create_if_block_2(ctx);
	let each_value_1 = /*filteredGames*/ ctx[12](/*day*/ ctx[24]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			tr = element("tr");
			td = element("td");
			div = element("div");
			h5 = element("h5");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(h5, "class", "title is-6");
			set_style(h5, "margin-bottom", "0");
			set_style(h5, "margin-top", "1rem");
			attr(div, "class", "is-flex");
			attr(td, "colspan", "50");
			attr(td, "class", "svelte-10qe530");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td);
			append(td, div);
			append(div, h5);
			append(h5, t0);
			append(div, t1);
			if (if_block) if_block.m(div, null);
			insert(target, t2, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty[0] & /*comp*/ 8) && t0_value !== (t0_value = /*day*/ ctx[24] + "")) set_data(t0, t0_value);
			if (/*dayIndex*/ ctx[26] === 0) if_block.p(ctx, dirty);

			if (dirty[0] & /*filteredGames, comp, compId, selectedTeam*/ 4233) {
				each_value_1 = /*filteredGames*/ ctx[12](/*day*/ ctx[24]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			if (if_block) if_block.d();
			if (detaching) detach(t2);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (141:6) 
function create_tab1_slot(ctx) {
	let div;
	let show_if = /*hasMultipleFields*/ ctx[13]();
	let t0;
	let table;
	let thead;
	let t20;
	let tbody;
	let current;
	let if_block = show_if && create_if_block_3(ctx);
	let each_value = /*comp*/ ctx[3].days;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			t0 = space();
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><th>Date:</th> 
              <th>Div</th> 
              <th class="is-hidden-touch">Team 1</th> 
              <th class="is-print-only"></th> 
              <th class="is-hidden-touch">Team 2</th> 
              <th class="is-hidden-desktop">Game</th> 
              <th class="is-hidden-touch">Ref 1</th> 
              <th class="is-hidden-touch">Ref 2</th> 
              <th class="is-hidden-desktop">Refs</th> 
              <th>Duty</th> 
              <th class="is-hidden-touch"></th></tr>`;

			t20 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(table, "class", "table is-striped is-hoverable is-fullwidth is-centered-cells svelte-10qe530");
			set_style(table, "font-size", ".8rem");
			attr(div, "slot", "tab1");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append(div, t0);
			append(div, table);
			append(table, thead);
			append(table, t20);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			/*tbody_binding*/ ctx[20](tbody);
			current = true;
		},
		p(ctx, dirty) {
			if (show_if) if_block.p(ctx, dirty);

			if (dirty[0] & /*filteredGames, comp, compId, selectedTeam, updateTeamFilter, teams*/ 20873) {
				each_value = /*comp*/ ctx[3].days;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(tbody, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			transition_out(if_block);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
			/*tbody_binding*/ ctx[20](null);
		}
	};
}

// (249:8) {:else}
function create_else_block(ctx) {
	let tabs;
	let t;
	let ladder_1;
	let current;
	tabs = new Tabs({ props: { tabs: /*poolNames*/ ctx[2] } });
	tabs.$on("tabChange", /*tabChange_handler*/ ctx[18]);

	ladder_1 = new Ladder({
			props: {
				ladder: /*ladder*/ ctx[9],
				teams: /*comp*/ ctx[3].teams
			}
		});

	return {
		c() {
			create_component(tabs.$$.fragment);
			t = space();
			create_component(ladder_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tabs, target, anchor);
			insert(target, t, anchor);
			mount_component(ladder_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tabs_changes = {};
			if (dirty[0] & /*poolNames*/ 4) tabs_changes.tabs = /*poolNames*/ ctx[2];
			tabs.$set(tabs_changes);
			const ladder_1_changes = {};
			if (dirty[0] & /*ladder*/ 512) ladder_1_changes.ladder = /*ladder*/ ctx[9];
			if (dirty[0] & /*comp*/ 8) ladder_1_changes.teams = /*comp*/ ctx[3].teams;
			ladder_1.$set(ladder_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tabs.$$.fragment, local);
			transition_in(ladder_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tabs.$$.fragment, local);
			transition_out(ladder_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tabs, detaching);
			if (detaching) detach(t);
			destroy_component(ladder_1, detaching);
		}
	};
}

// (247:8) {#if pools.length <= 0}
function create_if_block(ctx) {
	let info;
	let current;

	info = new Info({
			props: { message: "No games have finished yet." }
		});

	return {
		c() {
			create_component(info.$$.fragment);
		},
		m(target, anchor) {
			mount_component(info, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(info.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(info.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(info, detaching);
		}
	};
}

// (246:6) 
function create_tab2_slot(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*pools*/ ctx[4].length <= 0) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, [-1, -1]);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			attr(div, "slot", "tab2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

// (254:6) 
function create_tab3_slot(ctx) {
	let div;
	let playerladder;
	let current;
	playerladder = new PlayerLadder({ props: { compId: /*compId*/ ctx[0] } });

	return {
		c() {
			div = element("div");
			create_component(playerladder.$$.fragment);
			attr(div, "slot", "tab3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(playerladder, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const playerladder_changes = {};
			if (dirty[0] & /*compId*/ 1) playerladder_changes.compId = /*compId*/ ctx[0];
			playerladder.$set(playerladder_changes);
		},
		i(local) {
			if (current) return;
			transition_in(playerladder.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(playerladder.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(playerladder);
		}
	};
}

// (257:6) 
function create_tab4_slot(ctx) {
	let div;
	let editteams;
	let current;

	editteams = new EditTeams({
			props: {
				compId: /*compId*/ ctx[0],
				teamsLookup: /*comp*/ ctx[3].teams
			}
		});

	return {
		c() {
			div = element("div");
			create_component(editteams.$$.fragment);
			attr(div, "slot", "tab4");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(editteams, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const editteams_changes = {};
			if (dirty[0] & /*compId*/ 1) editteams_changes.compId = /*compId*/ ctx[0];
			if (dirty[0] & /*comp*/ 8) editteams_changes.teamsLookup = /*comp*/ ctx[3].teams;
			editteams.$set(editteams_changes);
		},
		i(local) {
			if (current) return;
			transition_in(editteams.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(editteams.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(editteams);
		}
	};
}

// (260:6) 
function create_tab5_slot(ctx) {
	let div;
	let admin;
	let current;
	admin = new Admin({ props: { comp: /*comp*/ ctx[3] } });

	return {
		c() {
			div = element("div");
			create_component(admin.$$.fragment);
			attr(div, "slot", "tab5");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(admin, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const admin_changes = {};
			if (dirty[0] & /*comp*/ 8) admin_changes.comp = /*comp*/ ctx[3];
			admin.$set(admin_changes);
		},
		i(local) {
			if (current) return;
			transition_in(admin.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(admin.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(admin);
		}
	};
}

// (124:20)    <Loading /> {:then c}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 23,
		error: 33,
		blocks: [,,,]
	};

	handle_promise(promise = /*compPromise*/ ctx[15], info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			update_await_block_branch(info, ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

const click_handler = e => e.stopPropagation();
const func = f => `Field ${f}`;
const click_handler_1 = e => e.stopPropagation();

function instance($$self, $$props, $$invalidate) {
	let comp;
	let ladder;
	let pools;
	let teams;
	let selectedTeam;
	let filteredGames;
	let updateLadder;
	let updatePools;
	let { compId } = $$props;
	let fields = [1];
	let fieldTab = "All Fields";
	let poolNames = [];
	let tableBody;
	let teamFilter = ""; // team uuid.
	const hasMultipleFields = () => fields.length > 1;

	const updateTeamFilter = changeEvent => {
		$$invalidate(17, teamFilter = changeEvent.target.value); // team uuid.
		$$invalidate(7, selectedTeam = teamFilter);
	};

	const scrollToNewGame = async () => {
		const firstNewGame = comp.games.find(g => g.gameState !== "finished");

		if (firstNewGame) {
			tableBody.querySelector(`[data-name="${firstNewGame.id}"]`).scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const getComp = async () => {
		$$invalidate(3, comp = await get(`comps/${compId}`));
		let games = mapStartToDate(comp.games);
		games = calcGoalsForTeams(games);
		games = sortGames(games);
		$$invalidate(3, comp.games = games, comp);
		$$invalidate(5, fields = getFields(comp.games));
		$$invalidate(3, comp.days = getDays(comp.games), comp);
		$$invalidate(8, teams = Object.values(comp.teams).sort((a, b) => a.name < b.name));

		// wait for elements to be populated..
		new Promise(resolve => setTimeout(
				() => {
					scrollToNewGame();
					resolve();
				},
				500
			));

		return comp;
	};

	let compPromise = getComp();

	const onTableClick = e => {
		const oldTeam = selectedTeam;
		const newTeam = e.target.dataset.teamId;

		if (oldTeam === newTeam) {
			$$invalidate(7, selectedTeam = undefined);
		} else {
			$$invalidate(7, selectedTeam = newTeam);
		}
	};

	const tabChange_handler = e => updateLadder(e.detail);
	const tabChange_handler_1 = event => $$invalidate(1, fieldTab = event.detail);

	function tbody_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			tableBody = $$value;
			$$invalidate(6, tableBody);
		});
	}

	$$self.$$set = $$props => {
		if ('compId' in $$props) $$invalidate(0, compId = $$props.compId);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*comp, fieldTab, teamFilter*/ 131082) {
			$: $$invalidate(12, filteredGames = day => {
				const dayGames = comp.games.filter(g => g.day === day);

				const fGames = fieldTab === "All Fields"
				? dayGames
				: dayGames.filter(g => {
						const fieldNum = +fieldTab.replace("Field ", "").trim();
						return g.field === fieldNum;
					});

				const teamGames = teamFilter
				? fGames.filter(g => {
						return g.t1 === teamFilter || g.t2 === teamFilter || g.r1 === teamFilter || g.r2 === teamFilter || g.duty === teamFilter;
					})
				: fGames;

				return teamGames;
			});
		}

		if ($$self.$$.dirty[0] & /*comp, pools*/ 24) {
			$: $$invalidate(10, updatePools = () => {
				$$invalidate(4, pools = getStartedPools(comp.games));
				$$invalidate(2, poolNames = pools.map(p => comp.pools[p].name));
				$$invalidate(9, ladder = ladderForPool(comp.games, pools[0]));
			});
		}

		if ($$self.$$.dirty[0] & /*pools, poolNames, comp*/ 28) {
			$: $$invalidate(11, updateLadder = currentPoolTab => {
				const pool = pools[poolNames.indexOf(currentPoolTab)];
				$$invalidate(9, ladder = ladderForPool(comp.games, pool));
			});
		}
	};

	$: $$invalidate(3, comp = {});
	$: $$invalidate(9, ladder = []);
	$: $$invalidate(4, pools = []);
	$: $$invalidate(8, teams = []);
	$: $$invalidate(7, selectedTeam = "");

	return [
		compId,
		fieldTab,
		poolNames,
		comp,
		pools,
		fields,
		tableBody,
		selectedTeam,
		teams,
		ladder,
		updatePools,
		updateLadder,
		filteredGames,
		hasMultipleFields,
		updateTeamFilter,
		compPromise,
		onTableClick,
		teamFilter,
		tabChange_handler,
		tabChange_handler_1,
		tbody_binding
	];
}

class Comp extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { compId: 0 }, null, [-1, -1]);
	}
}

export default Comp;