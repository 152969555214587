/* components/CompLink.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { longDateFormat } from "../utils/dateUtils";
import { Link } from "svelte-routing";
import Icon from "fa-svelte";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { getGoogleUrl } from "../utils/compUtils";

function create_if_block(ctx) {
	let a;
	let icon;
	let t0;
	let t1_value = /*comp*/ ctx[0].address + "";
	let t1;
	let a_href_value;
	let current;
	let mounted;
	let dispose;
	icon = new Icon({ props: { icon: faMapMarkerAlt } });

	return {
		c() {
			a = element("a");
			create_component(icon.$$.fragment);
			t0 = space();
			t1 = text(t1_value);
			set_style(a, "margin-left", "auto");
			attr(a, "href", a_href_value = getGoogleUrl(/*comp*/ ctx[0].address));
		},
		m(target, anchor) {
			insert(target, a, anchor);
			mount_component(icon, a, null);
			append(a, t0);
			append(a, t1);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", click_handler);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*comp*/ 1) && t1_value !== (t1_value = /*comp*/ ctx[0].address + "")) set_data(t1, t1_value);

			if (!current || dirty & /*comp*/ 1 && a_href_value !== (a_href_value = getGoogleUrl(/*comp*/ ctx[0].address))) {
				attr(a, "href", a_href_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(icon.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(icon.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			destroy_component(icon);
			mounted = false;
			dispose();
		}
	};
}

// (10:0) <Link to={`comps/${comp.id}`}>
function create_default_slot(ctx) {
	let div2;
	let h4;
	let t0_value = /*comp*/ ctx[0].name + "";
	let t0;
	let t1;
	let div1;
	let div0;
	let t2_value = longDateFormat(/*comp*/ ctx[0].start) + "";
	let t2;
	let t3;
	let t4;
	let hr;
	let current;
	let if_block = /*comp*/ ctx[0].address && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			h4 = element("h4");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			div0 = element("div");
			t2 = text(t2_value);
			t3 = space();
			if (if_block) if_block.c();
			t4 = space();
			hr = element("hr");
			attr(h4, "class", "title is-5 mb-0 is-size-6-mobile");
			attr(div0, "class", "has-text-dark");
			attr(div1, "class", "is-flex is-size-7-mobile");
			attr(div2, "class", "is-vcentered link p-2 svelte-8d6cy8");
			attr(hr, "class", "my-0");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, h4);
			append(h4, t0);
			append(div2, t1);
			append(div2, div1);
			append(div1, div0);
			append(div0, t2);
			append(div1, t3);
			if (if_block) if_block.m(div1, null);
			insert(target, t4, anchor);
			insert(target, hr, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*comp*/ 1) && t0_value !== (t0_value = /*comp*/ ctx[0].name + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*comp*/ 1) && t2_value !== (t2_value = longDateFormat(/*comp*/ ctx[0].start) + "")) set_data(t2, t2_value);

			if (/*comp*/ ctx[0].address) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*comp*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div1, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			if (detaching) detach(t4);
			if (detaching) detach(hr);
		}
	};
}

function create_fragment(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				to: `comps/${/*comp*/ ctx[0].id}`,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const link_changes = {};
			if (dirty & /*comp*/ 1) link_changes.to = `comps/${/*comp*/ ctx[0].id}`;

			if (dirty & /*$$scope, comp*/ 3) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

const click_handler = e => e.stopPropagation();

function instance($$self, $$props, $$invalidate) {
	let { comp } = $$props;

	$$self.$$set = $$props => {
		if ('comp' in $$props) $$invalidate(0, comp = $$props.comp);
	};

	return [comp];
}

class CompLink extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { comp: 0 });
	}
}

export default CompLink;